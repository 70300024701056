.comingSoon {
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}


//Search table
.search_table{
  &:focus{
    color: #8898aa;
    background-color: #fff;
    border-color: #5e72e4!important;
    box-shadow: 0 3px 9px rgba(50,50,9,0), 3px 4px 8px rgba(94,114,228,.1)!important;
  }
}