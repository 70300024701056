.article-search-opt {
  .published {
    padding: 6px 12px;
    background: rgba(45, 206, 137, 0.1);
    border-color: rgba(45, 206, 137, 0.1);
    color: #2DCE89;
  }

  .completed {
    padding: 6px 12px;
    background: rgba(94, 114, 228, 0.1);
    border-color: rgba(94, 114, 228, 0.1);
    color: #5E72E4;
  }

  .unpublished {
    padding: 6px 12px;
    border-color: #E93849;
    color: #E93849;
  }

  span {
    max-width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.article-name {
  #articleId {
    .ant-select-selection-selected-value {
      .article-search-opt span {
        max-width: 100%;
        text-overflow: initial;
        overflow: auto;
      }

      span.ant-tag {
        display: none;
      }
    }
  }
}