.pd0 {
  padding: 0px;
}
.pdr0 {
  padding-right: 0px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
}
.vw-9 {
  width: 90vw;
}
.bdcl-input {
  &:focus {
    border-color: #D8D8D8; 
    }
}

.wrap-blacklist {
  .bl-i {
    @media (min-width: 769px) {
      float: left;
      width: 88%
    }
  }
  .bl-search {
    @media (min-width: 768px) {
      float: right;
      // width: 15%;
    }
  }
  .addblacklist-header {
    .form-control {
      // border: 2px solid #D8D8D8;
      border-color: hsl(0,0%,70%);
      background: transparent;
      margin-bottom: 20px;
      &:focus {
      border-color: #D8D8D8; 
      }
    }
    .react-select {
      margin-bottom: 20px;
      .react-select__control {
        background-color: $close-bg;
        // border-color: $white !important;
        .react-select__placeholder {
          font-size: 0.875rem !important;
        }
        // .css-w8afj7-Input {
        //   color: $white;
        // }
        // .react-select__indicator.react-select__dropdown-indicator {
        //   color: $white;
        // }
      }
    }
  }
}

.modal-blacklist-discipline {
  @media (min-width: 576px) {
    max-width: 864.42px !important;
  }
  .modal-header {
    background: #F7FAFC;
  }
  .modal-body {
    padding: 0px;
    .card {
      margin-bottom: 0px;
      padding: 28px 45px 0px 45px;
      .card-header {
        border-bottom: none;
        padding: 0px;
      }
      .card-footer {
        padding: 1.25rem 0.8rem;
      }
    }
  }
}
