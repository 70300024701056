.authHeaderContent{
    position: absolute;
    width: 100%;
    left: 10%;
    top: 40%;
}
.login{
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
