.application-info-modal {
    min-width: 674px;

    p {
        color: #172B4D;
        font-weight: 400;

        &.expertise {
            font-size: 14px;
        }
    }

    .ant-modal-body {
        max-height: 655px;
        overflow-y: scroll;
    }

    .info-heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .meta-data {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #7D8BA4;

        &-primary {
            color: #172B4D;
        }
    }
}

.information-modal-open {
    .ant-modal {
        top: 10vh;
    }
}