// Custom style for only confirm modal
.confirm-modal {
    max-width: 350px;

    p {
        font-weight: 400;
    }

    strong {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #4B556F;
    }

    .ant-modal-header,
    .ant-modal-footer {
        text-align: center;
    }

    .ant-modal-header {

        .ant-modal-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #172B4D;
        }

    }

    .ant-modal-body {
        padding: 0 20px;
        text-align: center;
    }

    &.hide {
        .ant-modal-body {
            display: none;
        }
    }
}

.revaluate-modal {
    min-width: 600px;
}

.ant-modal-header,
.ant-modal-footer {
    border: none;
}

// Common style for both modals ( Confirm & Evaluate )
.ant-modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #172B4D;
}

.modal-cancel-btn {
    min-width: 107px;
    border-color: #E93849;
    color: #E93849;
}

.modal-submit-btn {
    min-width: 107px;
    border-color: #2DCE89;
    background-color: #2DCE89;
}

.ant-btn-primary {
    background-color: #2DCE89;
    border-color: #2DCE89;

    &:hover {
        background-color: #2DCE89;
        border-color: #2DCE89;
        transition: all .2s;
        opacity: .6;
    }
}

.ant-btn-ghost {
    border-color: #E93849;
    color: #E93849;

    &:hover {
        border-color: #E93849;
        transition: all .2s;
        opacity: .6;
        color: #E93849
    }
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    opacity: 1;
    transition: none;
}