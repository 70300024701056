.main-content {
  position: relative;
  min-height: 100vh;
  .main-content-container {
    width: 100%;

    &.sidelist-show {
      width: calc(100% - 365px);
    }
  }
  // Navbar
  .navbar-top {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }

    .col-xl-4 {
      @media (max-width: 768px) {
        margin-top: 40px !important;
      }
    }

    .col-xl-3 {
      @media (max-width: 1200px) {
        margin-top: 40px !important;
      }
    }
  }
}
