@charset "UTF-8";

@font-face {
  font-family: "vietcetera-cms";
  src: url("../fonts/icons/vietcetera-cms.eot");
  src: url("../fonts/icons/vietcetera-cms.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/icons/vietcetera-cms.woff") format("woff"),
    url("../fonts/icons/vietcetera-cms.ttf") format("truetype"),
    url("../fonts/icons/vietcetera-cms.svg#vietcetera-cms") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "vietcetera-cms" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "vietcetera-cms" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-appearance:before {
  content: "\61";
}
.icon-article:before {
  content: "\62";
}
.icon-brief:before {
  content: "\63";
}
.icon-dashboard:before {
  content: "\64";
}
.icon-employee:before {
  content: "\65";
}
.icon-book:before {
  content: "\66";
}
.icon-market:before {
  content: "\67";
}
.icon-paper:before {
  content: "\68";
}
.icon-pitch:before {
  content: "\69";
}
.icon-statistic:before {
  content: "\6a";
}
.icon-topic:before {
  content: "\6b";
}
.icon-drag:before {
  content: "\6c";
}
.icon-search:before {
  content: "\6d";
}
.icon-plus:before {
  content: "\6e";
}
.icon-readers:before {
  content: "\6f";
}
.icon-upload-file:before {
  content: "\70";
}
.icon-calendar:before {
  content: "\71";
}
.icon-attach:before {
  content: "\72";
}
.icon-close:before {
  content: "\73";
}
.icon-search-input:before {
  content: "\74";
}
.icon-arrow-double:before {
  content: "\75";
}
