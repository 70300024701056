.navbar-admin__scroll-container {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 10px;
  }
}

.navbar-admin__second {
  background-color: #151A61;
  padding: 0 1rem;
.nav-link {
    color: white;
    padding-bottom: 17px;
    padding-top: 17px;
    &:hover {
      color: #FF9022;
    }
    &.active {
      border-bottom: 5px solid #FF9022;
    }
  }
}