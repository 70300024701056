.children-list {
    .collection-table-header {
        height: 51px;
        margin-top: 3px;

        h3 {
            padding: 12px 24px !important;
            border-bottom: none;
        }

    }

    .collection-table-body {
        padding: 10px 24px;

        .collection-table-search {
            width: 100%;
            margin-right: 13px;

            .input-group-flush {
                padding: 6px 12px;

                .form-control {
                    height: auto !important;
                }

            }
        }

        .collection-table-add {
            button {
                background: #5E72E4;
                padding: 6px 12px;

                &:focus {
                    background: #5E72E4;
                }
            }
        }

    }

    .collection-table-body-move {
        margin: 0 16px;
        padding: 16px 24px;
        background: #F3F4FA;

        .collection-cursor {
            cursor: pointer;
        }
    }
}

.collection-table-language {
    span {
        font-size: 13px;
        color: #7D8BA4;
    }

    .custom-toggle {
        .custom-toggle-slider {
            border-color: #FB6340 !important;

            &::before {
                background: #FB6340 !important;
            }

            &::after {
                color: #FB6340 !important;
            }

        }
    }
}

.select2 {
    .select2-selection[aria-expanded="true"] {
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .select2-selection.select2-selection--single {

        .select2-selection__rendered {
            color: #172B4D !important;
            font-size: 14px;

            .select2-selection__placeholder {
                color: #ADB4BC !important;
                font-size: 14px;
            }
        }
    }
}

.select2-container {
    .select2-dropdown {
        margin-top: 8px !important;
        border-top: 1px solid #dee2e6 !important;
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;

        .select2-search {
            display: none;
        }

        .select2-results {
            .select2-results__option {
                padding: 12px 24px;
                max-height: 300px;

                &:hover {
                    background: #F3F4FA;
                }
            }
        }
    }
}