//
// Header
//

.header {
  position: relative;
  color: white;
  font-size: 0.75rem;
}
.header-login {
  position: absolute;
  right: 0;
  top: 0;
}
