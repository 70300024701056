.react-select {
  .react-select__placeholder,
  .react-select__value-container {
    font-size: 0.875rem !important;
  }

  .react-select__indicator-separator {
    background-color: transparent;
  }

  .react-select__value-container {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .react-select__multi-value__label {
    background-color: white;
    font-size: 12px;
    &::before {
      content: "#";
    }
  }
  .react-select__multi-value__remove {
    background-color: white;
    font-size: 12px;
    cursor: pointer;
  }
}

.custom-vietcetera-topic {
  .react-select__single-value {
    // padding: .555rem .6rem;
    // border-radius: $input-border-radius;
    // background: transparent;
    // border: 1px solid #0274f7;
    // color: #0274f7;
    // font-weight: bold;
    // font-size: 10px;
    // text-transform: uppercase;
    // line-height: 1.5;
    padding: 0.255rem 0.5rem;
    border-radius: 0.25rem;
    background: #e9ecee;
    // border: 1px solid #0274f7;
    color: #0274f7;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1.5;
    display: inline;
  }
}

// Information
.react-select1 {
  .react-select__placeholder,
  .react-select__value-container {
    font-size: 13.5px !important;
  }

  .react-select__indicator-separator {
    background-color: transparent;
  }

  .react-select__value-container {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .react-select__multi-value__label {
    background-color: white;
    font-size: 13.5px;
  }
  .react-select__multi-value__remove {
    background-color: white;
    font-size: 13.5px;
    cursor: pointer;
  }
}

.custom-vietcetera-topic {
  .react-select__single-value {
    // padding: 0.555rem 0.6rem;
    // border-radius: $input-border-radius;
    // background: transparent;
    // border: 1px solid #0274f7;
    // color: #0274f7;
    // font-weight: bold;
    // font-size: 10px;
    // text-transform: uppercase;
    // line-height: 1.5;
    padding: 0.255rem 0.5rem;
    border-radius: 0.25rem;
    background: #e9ecee;
    color: #0274f7;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1.5;
    display: inline;
  }
}

.react-select__menu {
  font-size: 13.5px;
}

.filter {
  .react-select__placeholder,
  .react-select__value-container {
    font-size: 13.5px !important;
  }
}

.number {
  color: #001dbc;
  font-weight: 600;
  font-size: 14px;
}
.number_content {
  font-weight: 600;
  font-size: 14px;
}

.vietcetera-tags-select {
  .react-select__multi-value__remove {
    display: none;
  }
}

.react-select__placeholder {
  color: rgb(178, 188, 199) !important;
}
