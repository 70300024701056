//
// Input group
//

.input-group {
  box-shadow: $input-box-shadow;
  border-radius: $input-border-radius;
  @include transition($transition-ease-in-out);

  .form-control {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}
.form-control-2 {
  .input-2 {
    input {
      border-right: none !important;
    }
  }
  .input-group-prepend {
    .input-group-text-2 {
      border-radius: $input-border-radius;
      border-left: none !important;
    }
  }
}
.input-group-text {
  @include transition($input-transition);

  i {
    font-size: $font-size-sm;
  }

  .custom-control {
    line-height: 1;
  }
}

.input-group-prepend {
  // Should add the value of border right in variables.scss
  .input-group-text {
    border-right: 0;
  }
  .input-group-text-2 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group-append {
  // Should add the value of border right in variables.scss
  .input-group-text {
    border-left: 0;
  }
}

// Merged input group

.input-group-merge {
  .form-control {
    &:not(:first-child) {
      border-left: 0;
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 0;
      padding-right: 0;
    }
  }
}

// Alternative input groups related to .form-control-alternative

.input-group-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  .form-control,
  .input-group-text {
    border: 0;
    box-shadow: none;
  }
}
.input-group-alternative-2 {
  border: none !important;
}
.input-range-form {
  .input-range__track {
    height: 0.4rem;
    .input-range__label--value {
      color: white;
      font-family: "Open Sans", sans-serif;
      display: inline !important;
    }
  }
  .input-range__track--active {
    background-color: #fabc31;
  }
  .input-range__label-container {
    color: white;
    font-family: "Open Sans", sans-serif;
    // display: none;
  }
  .input-range__slider {
    background-color: #fff;
    border: 2px solid #fabc31;
  }
}
.focused {
  .input-group-alternative {
    box-shadow: $input-focus-alternative-box-shadow !important;
  }
}

// Remove borders and paddings

.input-group-flush {
  box-shadow: none;

  > .form-control {
    padding: 0;
    border-width: 0;
    background-color: transparent;
  }

  > .input-group-prepend,
  > .input-group-append {
    > .input-group-text {
      padding: 0;
      padding-right: 1rem;
      border-width: 0;
      background-color: transparent;
    }
  }
}

// .focus class is applied dinamycally from theme.js

.focused {
  .input-group {
    box-shadow: $input-focus-box-shadow;
  }

  .input-group-text {
    color: $input-group-addon-focus-color;
    background-color: $input-group-addon-focus-bg;
    border-color: $input-group-addon-focus-border-color;
  }

  .form-control {
    border-color: $input-group-addon-focus-border-color;
  }

  .input-group-flush {
    box-shadow: none;
  }
}
