.parent-select-list {
  display: flex;
  flex-direction: row;
  color: #4b556f;

  .parent-list {
    width: 30%;
    border-right: 1px solid #e9ecef;
  }

  .children-list {
    width: 70%;
  }

  .parent-list,
  .children-list {
    h3 {
      font-size: 18px;
      padding: 20px 24px;
      color: #172b4d;
      border-bottom: 1px solid #e9ecef;
      margin: 0;
    }
  }

  .parent-list-ul {
    padding: 0;
    margin: 0;
    margin-right: -4px;
    list-style-type: none;
    height: 80vh;
    overflow-y: scroll;

    li {
      position: relative;
      padding: 15px 18px 15px 40px;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      border-bottom: 1px solid #e9ecef;
      border-left: 4px solid transparent;

      .published-title {
        display: flex;
        align-items: center;
        padding-right: 50px;

        .title {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 3px;
        }
      }

      &.published {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .published-title {
          display: flex;
          align-items: center;
          padding-right: 50px;

          .title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 3px;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #2dce89;
          left: 20px;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }

      &.active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 4px solid #5e72e4;
        background-color: #f3f4fa;
      }

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #fb6340;
        border-radius: 50%;
        left: 20px;
        top: 50%;
        transform: translate(0%, -50%);
      }

      &:hover {
        background-color: #f3f4fa;
      }

      .number {
        font-size: 13px;
        line-height: 18px;
        text-align: right;
        color: #7d8ba4;
        font-weight: normal;
      }

      .more {
        float: right;

        i {
          top: -20px;
          font-size: 22px;
        }
      }
    }
  }

  .btn-add {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5e72e4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid #e9ecef;
    cursor: pointer;

    i {
      margin-right: 10px;
    }
  }

  .card-footer {
    border-top: none;
  }

  .children-list-content {
    padding: 0 16px 16px;

    .table-scroll {
      height: 81vh;
      overflow-y: scroll;

      #table-check-all-collection-detail.indeterminate.custom-control-input:checked
        ~ .custom-control-label::after {
        background-image: none;
        content: "-";
        top: -7px;
        right: 0px;
        bottom: 0;
        color: #fff;
        font-size: 22px;
        left: -24px;
      }
    }

    .published {
      position: relative;
      padding-left: 15px;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #2dce89;
        left: 0px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    .unpublished {
      position: relative;
      padding-left: 15px;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fb6340;
        left: 0px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    td {
      cursor: pointer;
    }
  }
}

.topic-select-list {
  .parent-list-ul {
    height: 500px;
  }
  .children-list-content {
    .table-scroll {
      height: 500px;
    }
  }
}
