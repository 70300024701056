.ant-select-selection__choice__content {
	line-height: unset !important;
}

.ant-select-selection__choice__remove {
	line-height: 16px !important;
}

.blue-color-001DBC {
	color: #001dbc;
}

.italic-text {
	font-family: Open Sans;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #7d8ba4;
}

.chief-official-tag-container {
	.rounded-custom-bottom {
		border-radius: 0 0 4px 4px !important;
	}

	th {
		text-align: center;
		padding-bottom: 16px;

		&:first-child {
			text-align: start;
			padding-left: 40px;
		}
	}

	tbody {
		tr {
			height: 60px;

			td {
				text-align: center;

				&:first-child {
					text-align: start;
				}
			}
		}
	}
}
