.multiple-select-title {
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  padding: 4% 5%;
  font-size: 12px;
  width: 200px;
}
.bg-gray {
  background-color: #f3f4fa !important;
}
.icon-arrow-double {
  position: absolute;
  top: 0;
  right: 8px;
  font-size: 24px;
  color: #7d8ba4;
}
.multi-select {
  display: flex;
  h4 {
    margin-right: 10px;
    font-weight: 600 !important;
    font-size: 12px !important;
    display: flex;
    height: 100%;
    align-items: center;
  }
}
.multiple-menu-content {
  .ant-dropdown-menu-item {
    padding: 5px 10px !important;
  }
}
