.locked-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 780px;
    z-index: 999;
    background: rgba(221, 217, 217, 0.75);

    &__content {
        background: #4B556F;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        padding: 15px;
    }
}

.slideshow-section {
    position: relative;

    .slide-item {
        min-height: 190px;
        width: 220px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .add-slide-btn {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        // padding: 50px;
        background: #FFFFFF;
        border: 1px solid #e8e8e8;
        border-radius: 5px;

        &:hover {
            opacity: .7;
            transition: all .2s;
        }

        &_title {
            font-weight: 600;
            color: #172B4D;
        }
    }
}