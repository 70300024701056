.toolbar-right {
  position: fixed;
  right: 5px;
  top: 140px;
}

.brief-toolbar-right {
  position: fixed;
  right: 15px;
  top: 185px;
  width: 5%;
}
