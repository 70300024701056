.live-update-form {
    .description-section {
        .ql-snow .ql-picker {
            height: auto;
        }

        .ant-form-item-explain,
        .ant-form-item-extra {
            color: #ff4d4f;
        }
    }

    .ant-divider-horizontal {
        margin: 20px 0 16px;
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 100%
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        max-width: 284px;
        height: 184px;
    }
}