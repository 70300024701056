.ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 12px 16px !important;
}
