.header-search-bar-container {
	display: flex;
	align-items: center;
	position: relative;
	margin-right: -15px;
	margin-left: -15px;
	padding: 15px;
	height: 70px;
	background-color: #fff;
	border-radius: 4px 4px 0 0;

	.remove-border-selector {
		border: unset;
		background-color: unset;

		.ant-select-selection {
			border: unset;
			background: unset;

			&:focus {
				border: unset;
				background: unset;
				box-shadow: unset;
			}

			&:active {
				border: unset;
				background: unset;
				box-shadow: unset;
			}
		}
	}

	.ant-select-disabled {
		color: unset !important;
	}

	.ant-input-disabled {
		color: unset !important;
		background-color: unset !important;
		cursor: unset !important;
	}
}
