.edit-title-input {
    &_wrapper {
        width: 80%;
    }

    width: 100%;
    text-overflow: ellipsis;
    overflow: auto;
}

.warn {
    border: 1px solid red;
}

.preview-content {
    max-width: 568px;
    margin: 0 auto;

    iframe {
        width: 100%;
    }

    figure {
        img {
            width: 100%;
        }

        figcaption {
            margin-top: 8px;
            font-size: 14px;
            line-height: 24px;
            color: #757575;
            position: relative;
        }
    }
}