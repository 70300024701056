.feature-article-list-select-scroll {
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .feature-article-list-select {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e9ecef;
    margin: 8px 0;
    border-radius: 4px;
    padding: 12px 24px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .select-title {
      font-size: 14px;
      padding-right: 6px;
      color: #4b556f;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .select-remove {
      cursor: pointer;
      color: #7d8ba4;
    }
  }
}

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #5e72e4 !important;
    background-color: #5e72e4 !important;
    background: #5e72e4 !important;
  }

  .custom-control-label {
    &:active {
      border: none !important;
    }

    &::before {
      border: 2px solid #7d8ba4;
    }
  }
}

.feature-select {
  .input-group-merge {
    .feature-select-input-search {
      padding: 0 12px;
    }
  }

  .feature-select-checkbox {
    position: fixed;
    margin-top: 4px;
    margin-left: 1px;
    width: 92%;
    border: 1px solid #e6e8eb;
    box-sizing: border-box;
    border-radius: 4px;
    background: #ffffff;
    z-index: 5;

    .feature-select-checkbox-scroll {
      max-height: 40vh;
      padding: 0px 13px;
      overflow-x: auto;
      overflow-y: scroll;
      .custom-checkbox {
        margin: 8px 0px;

        .custom-control-input:checked ~ .custom-control-label::before {
          border-color: #5e72e4 !important;
          background-color: #5e72e4 !important;
          background: #5e72e4 !important;
        }

        .custom-control-label {
          &:active {
            border: none !important;
          }

          &::before {
            border: 2px solid #7d8ba4;
          }

          .custom-control-title {
            font-family: Open Sans;
            font-size: 14px;
            color: #4b556f;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .feature-select-checkbox-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #adb4bd;
      font-size: 14px;
      margin: 16px 0;
      margin-right: 16px;

      span {
        cursor: pointer;
      }
    }
  }
}

.pin-to-home {
  .feature-select {
    .input-group-merge {
      .feature-select-input-search {
        padding: 0 12px;
      }
    }

    .feature-select-checkbox {
      position: fixed;
      margin-top: 4px;
      margin-left: 1px;
      width: 92%;
      border: 1px solid #e6e8eb;
      box-sizing: border-box;
      border-radius: 4px;
      background: #ffffff;
      z-index: 5;

      .feature-select-checkbox-scroll {
        max-height: 40vh;
        padding: 0px 13px;
        overflow-x: auto;
        overflow-y: scroll;
        box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2),
          0 5px 15px rgba(0, 0, 0, 0.17);
        .custom-checkbox {
          margin: 8px 0px;

          .custom-control-input:checked ~ .custom-control-label::before {
            border-color: #5e72e4 !important;
            background-color: #5e72e4 !important;
            background: #5e72e4 !important;
          }

          .custom-control-label {
            &:active {
              border: none !important;
            }

            &::before {
              border: 2px solid #7d8ba4;
            }

            .custom-control-title {
              font-family: Open Sans;
              font-size: 14px;
              color: #4b556f;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .feature-select-checkbox-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #adb4bd;
        font-size: 14px;
        margin: 16px 0;
        margin-right: 16px;

        span {
          cursor: pointer;
        }
      }
    }
  }
}

.feature-article-table {
  .list {
    .topic-feature-table {
      padding: 7px 12px;
      background: #ffffff;
      border: 1px solid #7d8ba4;
      border-radius: 4px;
    }
  }
}

.topic-table {
  padding: 7px 12px;
  background: #ffffff;
  border: 1px solid #7d8ba4;
  border-radius: 4px;
}
