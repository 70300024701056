.header-contract {
    .form-control {
        border: 1px solid #fff !important;
        color: $white;
        // background-color: $close-bg;
        // background-color: $primary;
        background-color: #172b4d;
        margin-top: 20px;
        &::placeholder {
            color: $white;
        }
    }
    .input-group-alternative {
        box-shadow: none !important;
    }
    .input-group-text {
        background: $close-bg;
        border: 1px solid $white;
        color: $white;
        margin-top: 20px;
        @media (min-width:991px) and (max-width: 1058px) {
            display: none;
        }
        @media (max-width:523px) {
            display: none;
        }
    }
    .input-group .input-group-prepend + .rdt .form-control {
      border: none !important;
      border-bottom: 1px solid white !important;
      border-right: 1px solid white !important;
      border-top: 1px solid white !important;
      @media (min-width:991px) and (max-width: 1058px) {
        border-left: 1px solid white !important;
      }
      @media (max-width:523px) {
        border-left: 1px solid white !important;
      }
    }
    .custom-control.custom-checkbox{
        padding-top: 10px;
    }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #34BFA3;
    }
    button.btn.btn-warning {
        float: right;
        min-width: 123.32px;
    }
    .rdtPicker {
      @media (min-width: 1200px) {
        margin-left: -58px;
      }
      @media (max-width: 1199px) {
        margin-left: -47px;
      }
      @media (max-width: 992px) {
        margin-left: -56px;
      } 
    }
    .react-select {
      margin-top: 20px;
      .react-select__control {
        // background-color: $primary;
        background-color: #172b4d;
        border-color: $white !important;
        .react-select__placeholder {
          color: $white;
          font-size: 0.875rem !important;
        }
        .css-w8afj7-Input {
          color: $white;
        }
        .react-select__indicator.react-select__dropdown-indicator {
          color: $white;
        }
      }
      .css-1uccc91-singleValue {
        color: $white;
      }
    }
}
.wrap-table-contract {
    .card-contract {
        width: 100%;
        margin-top: 30px;
    }
}

.mr-3p {
  margin-right: 30px;
}