.card-feedback {

  .card-body {
    padding: 1rem;
    max-height: 400px;
    overflow-y: scroll;
  }

  .comment {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 0.5px rgba($color: #acafb1, $alpha: 0.6);

    .avatar {
      width: 33px;
      height: 33px;
    }

    h4 {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
    }

    .date {
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #8797aa;
    }

    .btn-reply {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: #0274f7;
    }

    .btn-resolve {
      width: 78px;
      border-radius: 6px;
      background-color: #e0f3ff;
      color: #0274f7;
      border: none;

      &:hover {
        background-color: #e0f3ff;
        color: #0274f7;
      }
    }

    .btn-resolved {
      width: 78px;
      border-radius: 6px;
      border: 1px solid rgba($color: #0274f7, $alpha: 0.2);
      background-color: #ffffff;
      color: rgba($color: #0274f7, $alpha: 0.2);

      &:hover {
        background-color: #ffffff;
        border: solid 1px #e9ecee;
        color: rgba($color: #0274f7, $alpha: 0.2);
      }
    }

    .btn-cancle {
      width: 78px;
      border-radius: 6px;
      border: solid 1px #e9ecee;
    }

    .input-reply {
      border-radius: 4px;
      box-shadow: 0 4px 6px 0 rgba(136, 152, 170, 0.15);
      border: solid 1px #5e72e4;
      background-color: #ffffff;
      font-size: 12px;
      padding: 0.2rem;

      input[type="text"] {
        border: none;
        padding: 0;
        width: calc(100% - 40px);
      }
    }
  }

  .reply {
    border-radius: 6px;
    padding: 12px 10px;
    border-radius: 6px;
    border: solid 1px #acafb1;
  }

}