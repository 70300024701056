.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    max-width: 284px;
    height: 184px;
}

.remove-btn {
    max-width: 284px;
}