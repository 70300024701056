//
// Icon buttons
//

// General styles

.btn {
    position: relative;
    text-transform: $btn-text-transform;
    transition: $transition-base;
    letter-spacing: $btn-letter-spacing;
    font-size: $input-btn-font-size;
    will-change: transform;

    &:hover {
        @include box-shadow($btn-hover-box-shadow);
        transform: translateY($btn-hover-translate-y);
    }

    &:not(:last-child) {
        margin-right: .5rem;
    }

   // Icons

    i:not(:first-child),
    svg:not(:first-child) {
        margin-left: .5rem;
    }

    i:not(:last-child),
    svg:not(:last-child) {
        margin-right: .5rem;
    }
}


// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
    .btn {
        margin-right: 0;
        transform: translateY(0);
    }
}


// Size variations

.btn-sm {
    font-size: $input-btn-font-size-sm;
}

.btn-lg {
    font-size: $input-btn-font-size-lg;
}


// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
    border-width: 1px;
}

.btn-outline-secondary {
    color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
    i:not(.fas):not(.fab) {
        position: relative;
        top: 2px;
    }
}

.btn-link {
    font-weight: $btn-font-weight;
    box-shadow: none;

    &:hover {
        box-shadow: none;
        transform: none;
    }
}

.btn-neutral {
    color: theme-color("primary");
}

.btn-simple{
  background-color: transparent;
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.delete{
    color: #5E72E4;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1);
    &:hover{
        color: #5E72E4;
    }
}
.add{
    background-color: #5E72E4;
    color: #FFFFFF;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1)!important;
    height: 34px;
    border-color: #5E72E4;
    font-weight: 600;
    &:hover{
        background-color: #5E72E4!important;
        color: #FFFFFF!important;
        transform: translateY(-1px)!important;
        border-color: #5E72E4!important;
    }
}
.view{
    background-color: #F6F9FC;;
    color: #5E72E4;
    height: 34px;
    border-color: #F6F9FC;
    margin: 0 10px;
    font-weight: 600;
    &:hover{
        background-color: #F6F9FC!important;
        color: #5E72E4!important;
        transform: translateY(-1px)!important;
        border-color: #F6F9FC!important;
    }
}