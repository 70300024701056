.status-new {
  color: #5243aa;
  font-size: 16px;
  margin-right: 6px;
}
.status-completed {
  color: #3bc88b;
  font-size: 16px;
  margin-right: 6px;
}
.status-progress {
  color: #008fe8;
  font-size: 16px;
  margin-right: 6px;
}
.status-cancel {
  color: #adb4bd;
  font-size: 16px;
  margin-right: 6px;
}
.status-waiting {
  color: #ffc917;
  font-size: 16px;
  margin-right: 6px;
}
.status-alert {
  color: #fb6340;
  font-size: 16px;
  margin-right: 6px;
}
