.application-detail {

    a {
        &.link {
            color: #001DBC;
        }
    }

    .sticky-action {
        position: sticky;
        bottom: 0;
        background: #fff;
    }

    .loading-placeholder {
        min-height: 600px;
    }


    .applicant-article {
        margin-top: 24px;
        background: #F6F9FC;
        border: 1px solid #F3F4FA;
        box-sizing: border-box;
        border-radius: 4px;
        max-height: 766px;
        padding: 32px 144px;
        overflow-y: scroll;
        font-family: Merriweather;
        color: #292929;
    }

    p {
        color: #172B4D;
        font-weight: 400;
    }

    .meta-data {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #7D8BA4;
    }

    .article-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .approve-btn-wrapper {
        width: 22%;
        display: flex;
        justify-content: flex-end;

        .ant-btn-primary {
            margin-left: 20px;
            background-color: #2DCE89;
            border-color: #2DCE89;

            &:hover {
                transition: all .2s;
                opacity: .6;
            }
        }

        .ant-btn-ghost {
            border-color: #2DCE89;
            color: #2DCE89;

            &:hover {
                transition: all .2s;
                opacity: .6;
                color: #2DCE89
            }
        }
    }

    .reject-btn {
        color: #F5365C;

        &:hover {
            transition: all .2s;
            opacity: .6;
        }
    }

    .view-old-draft {
        margin-top: 24px;
        display: flex;
        align-items: flex-start;

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #757575;
        }

        a {
            margin-left: 8px;
            font-size: 16px;
            line-height: 24px;
            color: #001DBC;
        }
    }
}

// Format applicant article
.application-detail {
    .applicant-article {

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: #292929;
            font-family: PoppinsVN;

            em a {
                font-style: italic;
            }

            strong a {
                font-weight: 700;
            }

            strong em a {
                font-weight: 700;
                font-style: italic;
            }
        }

        p {
            font-size: 18px;
            line-height: 32px;
            font-weight: 300;
            font-family: Merriweather;

            span {
                font-size: 18px;
                line-height: 32px;
                font-family: Merriweather;
            }

            strong {
                em {
                    font-weight: 700;
                    font-style: italic;
                }
            }
        }

        h1 a {
            font-family: "PoppinsVN", sans-serif !important;
            font-size: 40px;
            font-weight: bold;
        }

        h2 {
            font-size: 32px;
            line-height: 44px;
            margin-top: 64px;

            a {
                font-family: "PoppinsVN", sans-serif !important;
                font-size: 32px;
                font-weight: 600;
            }
        }

        h3 {
            font-size: 24px;
            line-height: 32px;
            margin-top: 64px;

            a {
                font-family: "PoppinsVN", sans-serif !important;
                font-size: 24px;
                font-weight: 600;
            }
        }

        h4 {
            font-size: 20px;
            line-height: 28px;
        }

        ol,
        ul {
            font-weight: 200;
        }

        strong {
            font-weight: 600;
        }
    }

    img {
        margin: 48px auto;
        width: 100%;
    }
}