.post-settings {
  position: relative;
  z-index: 2;
  a {
    font-size: 13px;
  }

  .ql-toolbar {
    display: none;
  }

  .ql-editor {
    min-height: 100px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
    p {
      font-size: 14px;
    }
  }

  .ql-container {
    border: none !important;
  }

  .accordion {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .col-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .rdt .form-control {
    color: #5e72e4 !important;
    text-decoration-line: underline;

    &::placeholder {
      color: #5e72e4 !important;
      text-decoration-line: underline;
    }
  }

  a.value {
    color: #5e72e4 !important;
    text-decoration-line: underline !important;
  }

  .dropzone {
    .label {
      z-index: 5;
      border-radius: 4px;
      font-weight: 600;
      font-size: 12px;
      position: absolute;
      padding: 0px 8px;
      top: 10px;
      left: 10px;
      color: #4b556f;
      background-color: rgba($color: #ffffff, $alpha: 0.67);
    }
  }
  #dropzone-new-article {
    margin: 0 auto;
  }
  #dropzone-new,
  #dropzone-new-article,
  #dropzone-three-two {
    overflow: hidden;
    width: 244px;
    height: 164px;
    min-height: 0px !important;
    .dz-message span {
      font-size: 12px;
    }
  }

  #dropzone-two-one {
    overflow: hidden;
    width: 244px;
    height: 122px;
    min-height: 0px !important;
    .dz-message span {
      font-size: 12px;
    }
  }

  #dropzone-two-three {
    overflow: hidden;
    width: 116px;
    height: 174px;
    min-height: 0px !important;
    .dz-message span {
      font-size: 12px;
    }
  }

  #dropzone-one-one {
    overflow: hidden;
    width: 116px;
    height: 116px;
    margin-left: 16px;
    min-height: 0px !important;
    .dz-message span {
      font-size: 12px;
    }
  }
}
