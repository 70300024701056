.sidelist {
  box-shadow: $navbar-vertical-box-shadow;
  background-color: #F7FAFC;
  position: relative;
  display: none;
  min-width: 365px !important;
  font-family: 'Open Sans', sans-serif;
  color: #3D3D3D;

  &.show {
    display: block;
  }

  .sidelist-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 365px;
    z-index: 100;
    background-color: #F7FAFC;
    height: 93vh;
    @media (min-width: 1200px) {
      margin-left: 60px;
    }

    .sidelist-header {
      padding: 5px 25px;
      background-color: white;
      position: fixed;
      top: 0;
      width: 365px;
      .col-md-6, .col-md-12 {
        padding: 5px;
      }
      z-index: 1000;
    }
    .sidelist-footer {
      padding: 5px 25px;
      background-color: white;
      font-size: 14px;
      position: fixed;
      width: 365px;
      bottom: 0;
      .col-md-6, .col-md-12 {
        padding: 5px;
      }
    }

    .sidelist-body {
      position: relative;
      top: 295px;
      overflow-y: scroll;
      height: calc(100vh - 355px);
      z-index: 1;
    }
  }
}

@media(min-width: 1200px) {
  .g-sidenav-pinned .sidelist .sidelist-container {
    margin-left: 250px;
  }
}

.adjustment-sidelist {
  padding: 10px;

  h1 {
    margin-top: 20px;
    font-weight: 200;
    padding-left: 20px;
  }

  ul {
    margin-top: 20px;
    a {
      color: #151A61;
      font-size: 14px;

      &.active {
        li {
          background-color: rgba($color: #FFB822, $alpha: 0.2);
          font-weight: bold
        }
      }
    }
    li {
      padding: 11px 5px;
      &:hover {
        background-color: rgba($color: #FFB822, $alpha: 0.2)
      }
    }
  }

}
