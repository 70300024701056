//
// Table
//

// General styles

.table {
  border: 1px solid #ccc;
  thead th {
    padding-top: $table-head-spacer-y;
    padding-bottom: $table-head-spacer-y;
    font-size: $table-head-font-size;
    text-transform: $table-head-text-transform;
    letter-spacing: $table-head-letter-spacing;
    border-bottom: $table-border-width solid $table-border-color;
  }

  th {
    font-weight: $table-head-font-weight;
  }

  td {
    .progress {
      height: 3px;
      width: 120px;
      margin: 0;
    }
  }

  td,
  th {
    font-size: $table-body-font-size;
    white-space: nowrap;
    border: 1px solid #dee2e6;
    height: 60px;
  }

  // Vetical align table content

  &.align-items-center {
    td,
    th {
      vertical-align: middle;
    }
  }

  // Styles for dark table

  .thead-dark {
    th {
      background-color: $table-dark-head-bg;
      color: $table-dark-head-color;

      a {
        color: $table-dark-head-color;
      }
    }
  }

  // Styles for light table

  .thead-light {
    th {
      background-color: $table-head-bg;
      color: black;

      a {
        color: black;
      }
    }
  }
}

.table-collapse {
  thead {
    th {
      background-color: #e8e8e8;
      color: black;
      font-size: 0.85rem;
      a {
        color: black;
      }
    }
  }

  .collapse-container {
    &.hide {
      .tr-collapse-content {
        display: none;
      }
    }
  }

  .tr-collapse-header {
    background-color: $table-head-bg;
    color: black;
    cursor: pointer;
    a {
      color: black;
    }
    th {
      i {
        float: right;
      }
    }
    &:hover {
      background-color: #d2dce6;
    }
  }
}

// Add transition for hover state

.table-hover {
  tr {
    @include transition($transition-base);
  }
}

// Flush tables

.table-flush {
  .td-hover {
    &:hover {
      background-color: rgb(0, 29, 188);
      color: white;
    }
  }
  td,
  th {
    border-left: 0;
    border-right: 0;
    border-bottom: transparent;
  }

  tbody {
    tr {
      &:first-child {
        td,
        th {
          border-top: 0;
        }
      }

      &:last-child {
        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}

.table-sidelist {
  border: none;
  th,
  td {
    border: none;
  }

  tr {
    cursor: pointer;
    &:hover {
      background-color: rgba($color: #ffb822, $alpha: 0.2);
    }
    &.selected {
      background-color: rgba($color: #ffb822, $alpha: 0.2);
      font-weight: bold;
    }
  }
}

.table-content {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  td {
    padding: 10px;
    &.label {
      width: 200px;
    }
    &.value {
      font-weight: bold;
    }
  }
}

.table-fixed {
  thead {
    th {
      background-color: #e8e8e8;
    }
  }
  tr {
    &:hover {
      background-color: #8cc6ec;
    }
  }
}

// Tables inside cards

.card {
  .table {
    margin-bottom: 0;

    td,
    th {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}

// Fixes for custom elements inside tables

.table {
  .custom-toggle {
    display: block;
  }
}

.scroll-container {
  overflow: scroll;
  max-height: 482px;
}

.department-table {
  th {
    background-color: rgba($color: #e8e8e8, $alpha: 0.2);
    font-weight: bold;
  }
  td,
  th {
    padding: 20px 25px !important;

    img {
      cursor: pointer;
      float: right;
    }
  }
  .tbody-scroll {
    max-height: 481px;
    overflow: scroll;
    tbody {
      width: 100%;
    }
    td {
      width: 100%;
    }
  }
}
.table-noti {
  tr {
    cursor: pointer;

    &:hover {
      background-color: rgba($color: #ccc, $alpha: 0.4);
    }
  }
}

._custom-table {
  border: 0px;
  margin: auto;
}

.flex-row-table {
  display: flex;
  align-items: center;
}

.mb-4.5 {
  margin-bottom: 4.5px;
}

.dragline {
  border-bottom: 1px solid rgb(0, 29, 188) !important;
}

.__view_more {
  font-size: 12px;
  font-weight: normal;
}

#__scrollbar-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

#__scrollbar-table::-webkit-scrollbar {
  height: 4px !important;
}
