//
// Modal
//

.modal-title {
  font-size: $modal-title-font-size;
}

// Fluid modal

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 0;
  }
}

.modal-form {
  margin-top: 130px;
  .label,
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4b556f;
  }

  @media (min-width: 576px) {
    max-width: 608px;
  }

  .modal-seperate {
    border-top: 1px solid #ccc;
    margin: 10px 0;
    margin-bottom: 40px;
  }

  .custom-toggle {
    top: 8px;
  }

  .note {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #7d8ba4;
  }

  .btn-delete {
    margin-top: 24px;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    color: #5e72e4;
  }
}

.modal-topic {
  .dz-message {
    height: 150px !important;
  }

  .react-tagsinput {
    height: 150px !important;
  }
}

.modal-artilce-list {
  position: absolute;
  height: 100vh;
  width: 50%;
  max-width: initial;
  right: 0;
  top: 0;
  margin: 0;

  .modal-content {
    width: 100%;
    height: 100%;
  }

  .modal-body {
    padding: 0;
    padding-left: 0;
    padding-right: 0;

    .table-container {
      border-top: 2px solid #dee2e6;
      height: calc(100vh - 115px);
      overflow-y: scroll;
    }

    .published,
    .unpublished {
      position: relative;
      padding-left: 15px;
    }

    .unpublished:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fb6340;
      left: 0px;
      top: 50%;
      -webkit-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
    }

    .published:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #2dce89;
      left: 0px;
      top: 50%;
      -webkit-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
    }
  }

  .thead-light {
    th {
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.9px;
      color: #7d8ba4 !important;
    }
  }
}

// Background color variations

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}
