.bootstrap-tagsinput .tag a{
  position: absolute;
  top: 50%;
  margin-left: 10px;
  cursor: pointer;
  transform: translate(0, -50%);
  color: #fff;

  &::after {
    font-size: 16px;
    content: "×";
  }
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding: 10px 5px 5px 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
}

.react-tagsinput--focused {
  border-color: #3C74F0;
}

.react-tagsinput-tag {
  background-color: transparent ;
  border-radius: 2px ;
  border: none ;
  color: #2a2f34;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}
.react-tagsinput-tag::before {
  content: "#";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-right: 5px;
  outline: none;
}

.react-tagsinput:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: #3C74F0;
  outline: 0;
  box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(60, 116, 240, 0.1);
}
