.feature-article-action-wrapper {
  padding-left: 65px;

  .left-column-action {
    span {
      font-size: 14px;
    }

    .last-edited {
      color: #ADB4BD;

      strong {
        color: #292929;
      }
    }
  }
}

.ant-calendar-today .ant-calendar-date {
  color: #ffffff !important;
  background: #2DCE89 !important;
  border: 1px solid #F4F6F8 !important;
  border-radius: 4px !important;
}