.btn + .btn {
  margin-left: 4px;
}
.btn-group + .btn-group {
  margin-left: 4px;
}
.btn.btn-sm.btn-.btn-icon-only.text-light {
  background-color: transparent;
}
.input-group {
  .btn + .btn {
    margin-left: -1px;
  }
}

._topic {
  padding: 0.255rem 0.5rem;
  border-radius: 0.25rem;
  background: #e9ecee;
  // border: 1px solid #0274f7;
  color: #0274f7;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1.5;
  display: inline;
  margin-right: 5px;
}

.db_topic{
  padding: 0.255rem 0.5rem;
  border: 1px solid #7D8BA4;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 1.5;
  display: inline;
  margin-right: 5px;
  color: #4B556F;
  font-weight: 600;
  font-size: 13px;
}