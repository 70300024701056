.tb-workingtime-month {
  .tr-vertical {
       display: block; float: left; 
      th {
        display: block;
      }
      td {
        display: block;
      }
  }
}

.tb_salary_emp {
  td {
    border: none;
    border-top: 1px solid #e9ecef;
  }
}