.application-table {
    .card-header {
        padding-bottom: 0
    }

    .ant-input-search {
        height: 100%;
    }

    .ant-table-placeholder {
        z-index: 0;
    }

    .ant-table-thead {
        th {
            background: none;
        }
    }

    .ant-table-header-column {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #4B556F;
    }

    .ant-table-body {
        a {
            &.link {
                color: #001DBC;
            }
        }
    }

    .action-section {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &_topic-select {
            float: right;
            width: 150px;
        }
    }
}