.timming-picker {
    input {
        &.form-control {
            &::-webkit-input-placeholder {
                /* Edge */
                color: #3C74F0;
            }

            &:-ms-input-placeholder {
                /* Internet Explorer */
                color: #3C74F0;
            }

            &::placeholder {
                color: #3C74F0;
            }
        }
    }

}

.popover-body {
    background: #172B4D;
    border-radius: 4px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.exclamation {
    margin-left: 9px;
}

.asterisk {
    color: red;
    font-size: 10px;
}

.dropzone-wrapper {
    position: relative;

    .loader-absolute {
        width: 50px;
        height: 50px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .dropzone-cover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        border: 1px dashed #E9ECEE;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        min-height: 248px;



        img {
            object-fit: cover;
        }

        &:hover {
            transition: .2s all;
            border: 1px dashed hsl(0, 0%, 70%);

        }
    }
}

.summary-field-form__item {
    .ant-form-item-label {
        text-align: left;
    }
}

.article-type-note {
    font-size: 12px;

    li {
        padding: 8px 0;
    }
}

.description {
    &.error {
        border: 1px solid red;
    }
}

.reset-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 8px;
}