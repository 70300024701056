.card-information{
  border: 1px solid #E9ECEE;
  box-sizing: border-box;
}
.information-title{
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7D8BA4;
  text-transform: uppercase;
}

.up-ava .dz-message{
padding-top: 93px;
}
.upload-avatar{
  display: block;
  .icon{
    width: 100%;
    font-size: 24px;
  }
  p{
    font-size: 14px;
    line-height: 16px;
    margin-top: -39px;
    color: #42526E;
  }
}
.cv{
  background-color: white!important;
  position: relative;
  color: #8898aa!important;
  span{
    margin-top: -10px;
    span{
      font-weight: 600;
      color: black;
    }
  }
  .choose{
    font-weight: 600;
    color: #172B4D;
  }
}
.file_cv{
  position: absolute;
  top: 39px;
  z-index: 99;
  color: #ADB4BD;
  padding-left: 20px;
  justify-content: center;
  width: 90%;
  .icon-upload{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      font-size: 15px;
    }
    .icon-attach::before{
      margin-right: 8px;
    }
    a{
      font-size:14px;
    }
  }
}
.top{
  margin-top: 103px;
}
@media screen and (max-width:600px){
  .top{
    margin-top: 0;
  }
}
.form-check-inline{
  margin-top: 25px;
}
.react-select1 {
  .css-yk16xz-control {
    border-color: #dee2e6;
  }
  .react-select__value-container{
    height: 46px;
  }
  .css-1wa3eu0-placeholder {
    color: #8898aa;
  }
}
.rdt input:hover {
  cursor: pointer;
}

.rdt{
  position: relative;
  z-index: 999;
  .form-control[readonly] {
  background-color: rgba(225,225,225, 0);
}
}
.icon-calendar{
  position: absolute;
  right: 28px;
  text-align: end;
  opacity: 1;
  margin-top: -39px;
  font-size: 20px;
  color: #7D8BA4;
}

.rdtActive{
  background-color: #5E71E3!important;
}
.view_profile{
.staff{
  background-color: white!important;
}
.calendar{
  .form-control:focus{
    background-color: #F3F4FA;
    color: #172B4D;
  }
}
.select_option{
  &:focus{
    background-color: #F3F4FA;
    color: #172B4D;
  }
  option{
    background-color: white;
  }
}
.css-1pahdxg-control:focus{
  background-color: #F3F4FA;
}
.css-yk16xz-control:focus{
  background-color: #F3F4FA;
}
}
.rdtDay.rdtToday.rdtActive, 
.rdtDay.rdtActive, 
.rdtDay.rdtActive:hover, .rdtDay.start-date, 
.rdtDay.end-date, .rdtDay.middle-date, 
.rdtDay.start-date:hover, .rdtDay.end-date:hover, 
.rdtDay.middle-date:hover{
background-color: #5E71E3!important;
}