.table-group-article {
  font-family: Open Sans;

  .ant-table-thead {
    th {
      background: #F6F9FC;
    }

    .ant-table-column-title {
      color: #7D8BA4;
      font-family: Open Sans;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.table-list-article {
  font-family: Open Sans;

  .ant-table-thead {
    display: none;
  }

  // .ant-table-tbody>tr>td:first-child {
  //   font-weight: 600;
  // }

}

.article-status {
  &.success {
    color: #52c41a;
  }

  &.error {
    color: #f5222d;
  }
}