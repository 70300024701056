.search-group{
    border: unset!important;
  .icon-search-input{
    font-size: 15px;
    width: 3px;
  }
  .form-control{
    border-left: 0;
    &:focus{
      border-color: #dee2e6;
    }
    }
  }
.view-brief{
  .card-search{
    margin: 20px;
  }
  .card{
    box-shadow: none;
  }
  .card-brief{
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: none;
    margin-right: 20px;
    margin-left: 20px;
    .form-group {
      margin-bottom: 0.5rem;
  }
    h6{
      color: #7D8BA4;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
    }
    .brief-left{
      .topic-day{
     display: flex;
     align-items: center;
     div{
      background: #E9EAEB;
      border-radius: 4px;
      padding: 0 5px;
      label{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #4B556F;
      }
     }
     li{
       margin-left: 30px;
       color: #7D8BA4;
       font-size: 13px;
       line-height: 18px;
     }
      }
    }
    .brief-right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button{
        box-shadow: unset;
      }
      .reject{
        color: #FB6340;
        box-shadow: unset;
        background-color: white;
        border-radius: 0;
        border-color: white;
        border-right: 1px solid #E9ECEF;
        font-weight: 600;
      }
    }
    @media screen and (max-width:576px){
      .brief-right{
      justify-content: center;
      margin-top: 20px;
      }
    }
  }
  .table-responsive{
    padding: 0 20px 10px 20px;
  }
  .multi-select{
    justify-content: flex-end;
  }
}

@media screen and(max-width:768px){
  .brief_filter{
    .multi-select:first-child{
      margin-bottom: 20px;
    }
  }
}

@media screen and(max-width:576px){
  .brief_filter{
    margin-top: 20px;
    .multi-select{
      justify-content: flex-start;
    }
    .multi-select:first-child{
      margin-bottom: 20px;
    }
  }
}
