.seo-manager {
  #dropzone-seo {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    height: 172px;
    min-height: 0px !important;
  }

  .ql-toolbar {
    display: none;
  }

  .ql-editor {
    min-height: 100px !important;
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
    p {
      font-size: 14px;
    }
  }

  .ql-container {
    border: none !important;
  }

  .ql-editor {
    font-family: "Open Sans", sans-serif !important;
  }

  .seo-preview {
    padding-top: 40px;
    margin-top: 20px;
    border-top: 1px solid #f3f4fa;
  }

  ul.nav {
    // margin-left: -10px;

    .nav-item {
      margin-right: 20px;
      &.active {
        border-bottom: 1px solid #5e72e4;
        .nav-link {
          color: #172b4d;
          padding: 10px 0 !important;
        }
      }
      .nav-link {
        padding: 10px 0 !important;
        color: #adb4bd;
      }
    }
  }
}

.preview-content {
  padding-top: 20px;
  .google-preview {
    padding: 16px;
    border: 1px solid #e9ecee;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .facebook-preview {
    border: 1px solid #e9ecee;
    box-sizing: border-box;
    border-radius: 4px;
    width: 385px;
    .image {
      img {
        width: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
    .facebook-card {
      padding: 16px;
    }
  }
  .twitter-preview {
    border: 1px solid #e9ecee;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    width: 580px;
    height: 114px;
    .image {
      width: 114px;
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    .twitter-card {
      max-width: 466px;
      padding: 16px;
    }
  }
  @media screen and (max-width: 576px) {
    .twitter-preview {
      display: block;
      .image {
        width: 100%;
        img {
          border-top-right-radius: 4px;
          border-bottom-left-radius: unset;
        }
      }
      .twitter-card {
        width: 100%;
      }
    }
  }
  .url {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #adb4bd;
    margin-bottom: 8px;
  }

  .title a {
    font-family: "Roboto";
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #001dbc;
    margin-bottom: 8px;
  }

  .excerpt p {
    font-size: 12px;
  }

  .description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #4b556f;
  }
}
