@import url("https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900&display=swap");
// @import "./customs/mixins.scss";

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-200.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-400.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-500.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-600.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-700.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-800.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "PoppinsVN";
  src: url("../fonts/Poppins/PoppinsVN-900.ttf") format("truetype");
  font-weight: 900;
}

body {
  font-family: "Open Sans", sans-serif;

  &.g-sidenav-pinned {
    .col-xl-9 {
      flex: 0 0 71%;
    }
  }
}
