.ant-drawer-content{
  .ant-drawer-wrapper-body{
    .ant-drawer-header{
      position: absolute;
      width: 100%;
  .ant-drawer-title{
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }
  // .ant-drawer-close{
  //   right: 50px;
  // }
}
.ant-drawer-body{
  padding: 0 3%;
}
.drawer-list{
  border: none;
  margin: 60px 0;
  th{
  font-size: 14px;
  line-height: 19px;
  padding-left: 0!important;
  color: #7D8BA4;
  }
  td{
    font-size: 14px;
    line-height: 19px;
    white-space:pre-line;
    }
  .article-title{
    color: #008FE8;
  }
  .topics{
    button{
      background: #E9EAEB;
      border-radius: 4px;
      border: none;
      padding: 0.255rem 0.5rem;
      display: inline;
      margin-right: 5px;
      line-height: 1.5;
    }
  }
  .tags{ 
    margin-right: 5px;
  span::before{
    content:"#";
      }
    }
    }
  .drawer-footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0px;
    background:white;
    border-radius: 0px 0px 4px 4px;
  }
  }
}
.nav-link.active {
  font-weight: 600!important;
  &::after{
    color: #001DBC!important;
  }
  i{
    color: #001DBC!important;
  }
}