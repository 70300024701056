//
// Bootstrap tags input
//


.bootstrap-tagsinput {
    // background-color: $input-bg;
    // border: $tags-input-border-width solid $tags-input-border-color;
    // display: inline-block;
    // padding: .25rem;
    // color: $tags-input-color;
    // vertical-align: middle;
    // border-radius: $input-border-radius;
    // max-width: 100%;
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    cursor: text;

    input {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0 6px;
        margin: 0;
        width: auto;
        max-width: inherit;

        &::placeholder {
            color: $input-placeholder-color;
            opacity: 1;
        }

        &:focus {
            border: none;
            box-shadow: none;
            color: #0274f7
        }
    }

    .badge {
        position: relative;
        // padding: .625rem .625rem .5rem;
        // margin: .125rem;
        border-radius: $input-border-radius;
        background: transparent;
        border: 1px solid #0274f7;
        color: #0274f7;
        // line-height: 1.5;
        @include box-shadow($tag-box-shadow);
        @include transition($transition-base);

        &:hover {
            padding-right: 1.5rem;
            color: #0274f7;
        }
    }

    [data-role="remove"] {
        margin-left: 10px;
        cursor: pointer;
        color: $tag-close-color;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        &:after {
            content: "×";
            font-size: 16px;
            color: #0274f7;
        }
    }
}
.bootstrap-tagsinput-topic {
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    cursor: text;

    input {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0 6px;
        margin: 0;
        width: auto;
        max-width: inherit;

        &::placeholder {
            color: $input-placeholder-color;
            opacity: 1;
        }

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .badge {
        position: relative;
        padding: .625rem .625rem .5rem;
        // margin: .125rem;
        border-radius: $input-border-radius;
        background: $tag-bg;
        color: $tag-color;
        // line-height: 1.5;
        @include box-shadow($tag-box-shadow);
        @include transition($transition-base);

        &:hover {
            padding-right: 1.5rem;
        }
    }

    [data-role="remove"] {
        margin-left: 10px;
        cursor: pointer;
        color: $tag-close-color;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        &:after {
            content: "×";
            font-size: 16px;
        }
    }
}
