.chart_dashboard{
    padding: 30px 0px 0 15px;
    flex-grow: 5; 
.chart_content{
  border-radius: 4px;
  background-color: #f3f4fa;
  padding: 4% 11%;
  .chart_content_h1{
    font-size: 32px;
    font-weight: bold;
    border-bottom: 1px solid #8797aa;
  }
  .chart_content_h2{
    font-size: 13px;
    color: #8797aa;
    font-weight: bold;
  }
    }
}
.chart_dashboard:first-child{
  padding-left: 32px !important;
}
.chart_dashboard:last-child{
  flex-grow: 10; 
  width: 250px;
  padding-right: 34px;
  .chart_content{
    padding: 6px 10px;
  }
}

.chart_bottom{
  text-align: center;
  color: #8797aa;
  font-size: 14px;
  font-weight: bold;
}
// none document.querySelector("[aria-labelledby='id-66-title']")
g[aria-labelledby]{
  display: none;
}