.tag-dropdown {
  height: 350px;
  overflow-y: scroll;

  .ant-select-dropdown-menu-item-group-list {
    display: flex !important;
    flex-wrap: wrap;
  }
}

.editor-add-new {
  .card-header {
    padding-right: 0;
    padding-left: 0;
  }

  .card-body {
    padding-right: 0;
    padding-left: 0;
  }
}