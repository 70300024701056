//
// Dropzone
//

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
}

.upload_cv {
  z-index: 9;

  .dz-message {
    padding: 0;
    padding-top: 9px;
    height: 47px !important;
    margin-top: -5px;
    border-radius: 4px;
    border: 1px dashed #dee2e6;
    // color: white;
    opacity: 1 !important;
    // &:hover {
    //   color: white;
    //   transform: translateY(-1px);
    //   box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    //     0 1px 3px rgba(0, 0, 0, 0.08);
    // }
  }
}

.dz-message {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $input-bg;
  border: $input-border-width dashed $input-border-color;
  border-radius: $border-radius;
  text-align: center;
  color: $text-muted;
  transition: $transition-base;
  order: -1;
  cursor: pointer;
  z-index: 999;
  height: 312px;

  &:hover {
    border-color: $text-muted;
    color: $body-color;
  }
}

.dz-drag-hover .dz-message {
  border-color: theme-color("primary");
  color: theme-color("primary");
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dropzone-single {
  .loader {
    display: flex;
    justify-content: center;
    top: 0;
    z-index: 9999;
    left: 0;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: fade-out($black, 0.1);
  color: white;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $border-radius;
}

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $border-radius;
}

.dz-preview-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
}

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

[data-dz-size] strong {
  font-weight: $font-weight-normal;
}

.Ava {
  .dz-message {
    padding-top: 90px;
    color: #adb5bd;
    height: 150px;

    &:hover {
      color: #3d3d3d;
      transform: translateY(-1px);
    }
  }
}

.Ava1:hover {
  opacity: 0.9 !important;
}

.Ava1 .dz-message:hover {
  padding-top: 90px;
  border: none;
  height: 315px;
  width: 100%;
  background: black;
  margin-top: -1px;

  p {
    color: white;
  }
}