.header-scroll {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  .header-top {
    .table-header {
      margin: 5px;
    }
    .header-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 24px;
      position: relative;
      .info-approval {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #7d8ba4;
        margin-right: 16px;
      }
      .info-number {
        font-weight: 600;
        font-size: 20px;
        line-height: 19px;
        color: #172b4d;
        margin-right: 16px;
        margin-bottom: 20px;
      }
      &::before {
        content: "";
        position: absolute;
        height: 65%;
        width: 2px;
        left: -16px;
        top: 0;
        background: #e9ecef;
      }
    }
  }
  .filter-box {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .info-approval {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #7d8ba4;
      margin-right: 16px;
    }
    .react-select {
      width: 186px;
      .react-select__value-container {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
