.slide-item {
    .ant-card-body {
        padding: 12px;
    }

    .slide-content-wrapper {
        &__title {
            margin: 0;
            font-weight: 600;
            line-height: 20px;
            color: #172B4D;
        }

        &__desc {
            margin: 0;
            font-family: Merriweather;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #7D8BA4;
        }
    }
}